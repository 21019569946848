/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails {
    getCurrentUserDetails
  }
`;
export const getAllTeamsOfUser = /* GraphQL */ `
  query GetAllTeamsOfUser {
    getAllTeamsOfUser
  }
`;
export const listQuizarreQuizRuns = /* GraphQL */ `
  query ListQuizarreQuizRuns($input: listQuizarreQuizRunsInput) {
    listQuizarreQuizRuns(input: $input)
  }
`;
export const listQuizRunLeaderboard = /* GraphQL */ `
  query ListQuizRunLeaderboard($input: listQuizRunLeaderboardInput) {
    listQuizRunLeaderboard(input: $input)
  }
`;
export const listQuizQuestions = /* GraphQL */ `
  query ListQuizQuestions($quiz_id: String) {
    listQuizQuestions(quiz_id: $quiz_id)
  }
`;
export const listQuizAttempts = /* GraphQL */ `
  query ListQuizAttempts {
    listQuizAttempts
  }
`;
export const listQuizAttemptQuestions = /* GraphQL */ `
  query ListQuizAttemptQuestions($input: listQuizAttemptQuestionsInput) {
    listQuizAttemptQuestions(input: $input)
  }
`;
export const getQuestionDetails = /* GraphQL */ `
  query GetQuestionDetails($input: getQuestionDetailsInput) {
    getQuestionDetails(input: $input)
  }
`;
export const getDocumentPresignedURL = /* GraphQL */ `
  query GetDocumentPresignedURL($input: commons3Input) {
    getDocumentPresignedURL(input: $input)
  }
`;
export const getAttemptDetails = /* GraphQL */ `
  query GetAttemptDetails($input: getAttemptDetailsInput) {
    getAttemptDetails(input: $input)
  }
`;
export const listTeamLeaderboard = /* GraphQL */ `
  query ListTeamLeaderboard($input: listTeamLeaderboardInput) {
    listTeamLeaderboard(input: $input)
  }
`;
