import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { Amplify } from "aws-amplify";
loadFonts()
async function initApp() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: "ap-south-1_IhJz0EbCT",
        userPoolClientId: "6unl5msvoa4ksr8cc3cnkrp913",
        loginWith: {
          email: true,
        },
        signUpVerificationMethod: "code",
      },
    },
    API: {
      GraphQL: {
        endpoint:
          "https://ulflbujzujgw3imelp2nkl6afe.appsync-api.ap-south-1.amazonaws.com/graphql",
        region: "ap-south-1",
        defaultAuthMode: "userPool",
      },
    },
  });
}
initApp().then(() => {
  createApp(App).use(router).use(store).use(vuetify).mount("#app");
});

