import { generateClient } from "aws-amplify/api";
import { getCurrentUserDetails } from "@/graphql/queries.js";
const client = generateClient();

export const GetCurrentUser = {
  data: () => ({}),
  methods: {
    async GetCurrentUserMethod() {
      try {
        let result = await client.graphql({
          query: getCurrentUserDetails,
          variables: {},
        });
        return JSON.parse(result.data.getCurrentUserDetails);
      } catch (error) {
        console.log("Err", error);
        return {
          status: "ERROR",
          message: error,
        };
      }
    },
  },
};
